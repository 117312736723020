import React from 'react'
import { Link } from 'react-router-dom';
import bg from "../../assets/images/servbg.png";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import logo from "../../assets/images/CovaCabsLogo.jpg"

const Footer = () => {
    return (
        < div className="serv_parent  pt-5 sm:pt-10 md:pt-20 lg:pt-28 mt-10" style={{backgroundImage: `url(${bg})`}}>
             <div className=' container mx-auto px-4 md:px-8'>
                <div className='flex justify-center'>
                    <img src={logo} className='w-[30%]'/>
                </div>
                <div className=' flex gap-5 md:gap-x-5 justify-center flex-wrap lg:flex-nowrap gap-y-4 mt-16'>
                        <div className=' w-full md:w-full lg:w-[25%] text-[#BDBDBD]'>
                            <p>
                            At Covaicabs, we are dedicated to providing top-notch taxi services to the residents and visitors of Coimbatore. Whether you need a ride to the airport, a quick trip across town, or a comfortable journey to a nearby city, our professional drivers and well-maintained vehicles are at your service. </p>                        </div>
                        <div className=' w-full md:w-full lg:w-[25%] text-[#BDBDBD]' id='contact'>
                                <h3 className=' font-semibold text-xl md:text-2xl text-white'>Contact Us</h3>
                                <ul className=' mt-5 flex flex-col gap-y-3 md:gap-y-6'>
                                    <li className=' flex gap-x-2 items-center'><span className=' flex-shrink-0 w-[30px] h-[30px] text-white text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaLocationDot /></span><span>Coimbatore,Tamil Nadu
</span></li>
                                    <li><a href="tel:+918883417776"  className=' flex gap-x-2 items-center w-full group  '><span className=' flex-shrink-0 w-[30px] h-[30px] text-white text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaPhone   /></span><span className='group-hover:text-[#E4A900]'>+91 8883417776</span></a></li>
                                    <li className=' flex gap-x-2 items-center'><a href='covaicabsin@gmail.com'  className=' flex gap-x-2 items-center w-full group '><span className=' flex-shrink-0 w-[30px] h-[30px] text-white text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><IoMail /></span><span className='group-hover:text-[#E4A900]'>covaicabsin@gmail.com</span></a></li>
                                </ul>
                        </div>
                        {/* <div className=' w-full md:w-full lg:w-[50%] text-[#BDBDBD]'>
                        <h3 className=' font-semibold text-xl md:text-2xl text-[#E4A900]'>One Way Taxi Cities</h3>
                        <ul className=' mt-5 grid grid-cols-1 gap-x-2 gap-y-3  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3'>
                            <ul className=' flex flex-col gap-y-3'>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Chennai</Link>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Coimbatore</Link>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Bangalore</Link>
                            </ul>
                            <ul className=' flex flex-col gap-y-3'>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Salem</Link>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Madurai</Link>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Pondicherry</Link>
                            </ul>
                            <ul className=' flex flex-col gap-y-3'>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Vellore</Link>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Trichy</Link>
                                <Link className='text-white hover:text-[#E4A900] transition-all'>Kumbakonam</Link>
                            </ul>
                        </ul>

                        </div> */}
                </div>
            </div>
            <div className=' bg-black mt-3 sm:mt-5 md:mt-8 px-4 py-4 sm:py-7'>
                <ul className=' flex flex-col sm:flex-row justify-center gap-x-4 items-center'>
                    <li><Link to="/terms-and-conditions" className=' block text-[#E4A900] hover:text-white transition-all'>Terms & Conditions</Link></li>
                    <li><Link to="/privacy-policy" className=' block text-[#E4A900] hover:text-white transition-all'>Privacy Policy</Link></li>
                </ul>
            </div>
            <div className='py-4 sm:py-7 text-white bg-[#2a2f3c] text-center px-4'>
            Copyright @ 2024 <Link className='text-[#E4A900] hover:text-[#fff] transition-all'>Covai Cabs </Link>. All Rights Reserved.
            </div>
        </div>
    )
}

export default Footer