import React from "react";
import BookingForm from "../components/BookingForm";
import About from "../components/About";
import PopularRoutes from "../components/PopularRoutes";
import Cars from "../components/Cars";
import Look from "../components/Look";
import Packages from "../components/Home/Packages";

import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import PricePlan from "../components/Home/PricePlan";
import LuxuryPackage from "../components/Home/LuxuryPackage";
import Cabs from "../components/Home/Cabs";
import PackageService from "../components/Home/PackageService";
import Testimonial from "../components/Home/Testimonial"
const Home = () => {
  return (
    <>
      <BookingForm />
      <About />
      <Packages />
      { /* <LuxuryPackage /> */}
      <Cabs />
      <PackageService />
      {/* <PricePlan /> */}
      <OurServices />
      {/* <OneWayServices />
      <TopRoutes /> */}
      {/* <PopularRoutes />
      <Cars /> */}
      <Testimonial />
      <Look />
    </>
  );
};

export default Home;
