import React from 'react'
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FreeMode, Pagination, Navigation, Autoplay } from 'swiper/modules';

const Testimonial = () => {
  return (
    <div className='flex items-center justify-center flex-col '>
           <h1 className="text-cent font-bold text-5xl p-10">Happy Customers</h1>
      <Swiper
        loop={true}         
           autoplay={{
          delay: 3000, 
          disableOnInteraction: false // Autoplay will not be disabled after user interactions
        }}
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          700: {
            slidesPerView: 3,
            spaceBetween: 15
          }
        }}
        freeMode={true}
        pagination={{
          clickable: true
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }} 
        modules={[FreeMode, Pagination, Navigation, Autoplay]}
        className='max-w-[90%] lg:max-w-[80%] p-2 mt-3'
      >
        <SwiperSlide className='mb-20 bg-[#921d88] rounded-3xl text-white p-10'>
          {/* <img src={chennai} alt="Chennai" /> */}
          <div className='flex flex-col h-[32vh]'>
          <p className="text-center font-semibold text-md mt-3">Reliable and friendly service every time! Covaicabs is my go-to for all my travel needs in Coimbatore.</p>
                           <span className='place-self-end pr-[20px]'> - Ramya</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mb-20 bg-[#921d88] rounded-3xl text-white p-10 '>
          {/* <img src={chennai} alt="Chennai" /> */}
          <div className='flex flex-col h-[32vh]'>
          <p className="text-center font-semibold text-md mt-3">I always choose Covaicabs for my airport transfers. They are punctual and professional.</p>
                           <span className='place-self-end pr-[20px]'> - Karthik</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mb-20 bg-[#921d88] rounded-3xl text-white p-10'>
          {/* <img src={chennai} alt="Chennai" /> */}
          <div className='flex flex-col'>
          <p className="text-center font-semibold text-md mt-3">"Fantastic service! Covaicabs has been my go-to taxi service in Coimbatore for over a year now. The drivers are always professional and courteous, and the vehicles are impeccably clean. Highly recommended!".</p>
                           <span className='place-self-end pr-[20px]'> - Arjun M</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mb-20 bg-[#921d88] rounded-3xl text-white p-10'>
          {/* <img src={chennai} alt="Chennai" /> */}
          <div className='flex flex-col'>
          <p className="text-center font-semibold text-md mt-3">"I had an early morning flight and was worried about getting to the airport on time. Covaicabs was prompt and reliable, ensuring I reached with plenty of time to spare. Their service is top-notch and stress-free.".</p>
                           <span className='place-self-end pr-[20px]'> - Priya S</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mb-20 bg-[#921d88] rounded-3xl text-white p-10 '>
          {/* <img src={chennai} alt="Chennai" /> */}
          <div className='flex flex-col h-[32vh]'>
          <p className="text-center font-semibold text-md mt-3">"We used Covaicabs for a weekend trip to Ooty. The driver was knowledgeable and took us through the most scenic routes. The ride was comfortable and enjoyable, making our trip even more memorable."</p>
                           <span className='place-self-end pr-[20px]'> - Rajesh K</span>
          </div>
        </SwiperSlide>

        {/* Navigation buttons */}
        {/* <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div> */}
      </Swiper>
    </div>
  )
}

export default Testimonial
