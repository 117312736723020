import React, { useEffect, useState } from 'react'
import { MdLocationPin } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import logo from '../assets/images/CovaiCabs.png';
import { RxHamburgerMenu } from 'react-icons/rx';
import { AiFillCloseCircle } from 'react-icons/ai';
import NavList from './NavList';
import { Link } from 'react-router-dom';
import { FaPhone, FaWhatsapp } from 'react-icons/fa';
import { IoMail } from "react-icons/io5";

import Contact from './Contact';
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [openNav, setOpenNav] = useState(false)
    function openNavBat() {
        setOpenNav(true);
    }
    function closeNavBar() {
        setOpenNav(false);
    }

    useEffect(() => {
        // Define a function to handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Conditionally apply the class based on the state
    // const headerClass = isScrolled ? 'header_parent header_scrolled' : 'header_parent';

    return (
        <div className='bg-white'>
            <div className='container  h-[150px] relation px-5 pb-6 flex items-center justify-around gap-x-5  mx-auto'>
                < div className="w-[50%] md:w-[20%]">
                    <img src={logo}/>
                </div>
                 {/* <div className="">Our Coimbatore Local Tourism Tarrif</div> */}
                <div className='hidden lg:block md:block'>
                    <Contact image={<IoMail className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white outline-black "/>} Label={"Email Address :"} contact={"covaicabsin@gmail.com"} />

                </div>
                <div className='hidden lg:block md:block'>

                    <Contact image={<FaWhatsapp  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  text-[20px] text-white"/>} Label={"Whatsapp Enquiry :"} contact={"+918883417776"} isphone='1' />
                </div>
                <div className='block lg:hidden cursor-pointer pr-8 text-white'>
                                        <span onClick={openNavBat} className="p-[15px]"><RxHamburgerMenu className=' text-[24px] md:text-[28px] text-white'/></span>
                                     </div>
                                     <div className={ `fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
                              <div className='px-4 py-3 bg-[#ffc9cb]'>
                                <img src={logo} alt="logo" className='w-[150px] mx-auto px-5' />
                              </div>
                               <div className='py-6 px-4'>
                                 <NavList ul_class="font-semibold text-[14px] text-black flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
                                 <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
                               </div>
                            </div>


                   {/* <div className='hidden lg:block md:block '>
                      <a href="#book_taxi_form" className='th-btn bg-[#333333]'>Book Now</a>
                 </div> */}

            </div>
            <div className='px-2 text-black bg-neutral-300 absolute left-[50px] top-[120px]  right-[50px] z-[12]'>

                      <div className='relative '>
                                <div className='flex gap-x-5 justify-between items-center '>
                                    <div className='hidden font-medium  lg:inline-block w-[70%] pr-0 md:pr-10 lg:pr-16 xl:pr-20'>
                                      <NavList ul_class="text-black flex p-[5px]  gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-white items-center nav_link_parent" li_class="text-black" />
                                     </div>

                                </div>   

                      </div>

          </div>
        </div>
        // <div className={headerClass}>
        //     <div >
        //         <div className='bg-[#5d5fef]'>
        //             <div className='container text-white mx-auto relative px-0 sm:px-4 md:px-8 lg:px-16 py-1 md:py-2 font-semibold'>
        //                 <marquee>Service starts @ Rs.14 per for details view our tariff</marquee>
        //             </div>
        //         </div>
        //         <div className=' bg-white'>
        //             <div className='relative bg-black'>
        //                 <div className='flex gap-x-5 justify-between items-center '>
        //                     <div className=' w-[65%]  md:w-[20%]  gap-x-5 '>
        //                     <Link to="/" className=' '>
        //                         <img src={logo} alt="logo" className=' w-[200px]  ' />
        //                         </Link>
        //                         </div> 
        //                     <div className='hidden font-medium  lg:inline-block w-[70%] pr-0 md:pr-10 lg:pr-16 xl:pr-20'>
        //                         <NavList ul_class="flex gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-white items-center nav_link_parent" li_class="" />
        //                     </div>
        //                     <div className='block lg:hidden cursor-pointer pr-8 text-white'>
        //                         <span onClick={openNavBat} ><RxHamburgerMenu className=' text-[24px] md:text-[28px]'/></span>
        //                     </div>
        //                 </div>
        //                 <div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
        //                     <div className='px-4 py-3 bg-[#ffc9cb]'>
        //                         <img src={logo} alt="logo" className='w-[150px] mx-auto px-5' />
        //                     </div>
        //                     <div className='py-6 px-4'>
        //                         <NavList ul_class="font-semibold text-[14px] flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
        //                         <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Header