import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Why Choose Covai Cabs?        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4">
          <h3 className="font-semibold text-3xl  sm:text-2xl">Professional Drivers
          </h3>
              <div className="p-5 border-2 ">
                <div className="text-medium font-semibold pr-2">Our drivers are experienced, courteous, and dedicated to ensuring a safe and pleasant ride for every passenger.</div>
              </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Clean and Comfortable Vehicles:</h3>
          <div className="p-5 border-2 ">
                <div className="text-medium font-semibold pr-2">We maintain a fleet of modern vehicles, ensuring cleanliness and comfort for all our customers.
                  </div>
                </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Corporate Servicess:</h3>
          <div className="p-5 border-2 ">
                <div className="text-medium font-semibold pr-2">We provide tailored corporate transportation solutions for businesses in Coimbatore. Whether it's daily employee commutes or executive travel, Covaicabs ensures timely and professional service.</div>
              </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Affordable Ratess:</h3>
          <div className="p-5 border-2 ">
                <div className="text-medium font-semibold pr-2">We offer competitive pricing with no hidden charges. Enjoy transparent and affordable fares for all your rides.</div>
              </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Easy Booking:</h3>
          <div className="p-5 border-2 ">
                <div className="text-medium font-semibold pr-2">Book your ride effortlessly through our user-friendly website or mobile app. You can also call our customer service team for instant bookings.</div>
              </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Book Your Ride with Covaicabs Today!</h3>
          <div className="p-5 border-2 ">
               
                <div className="text-medium font-semibold pr-2">Experience the best in local transportation with Covaicabs. Whether you're heading to a meeting, catching a flight, or exploring Coimbatore, we've got you covered. Contact us today to book your ride or download our app for convenient, on-the-go bookings.</div>
                <div className="font-bold text-3xl text-center mt-4">
                  Contact Us                   </div>

                    <div className="  flex flex-col items-center mt-3">
                      <div className="text-center text-lg"><span className="font-semibold text-xl">Phone : </span>+91 8883417776</div>
                      <div className="text-center text-lg" ><span className="font-semibold text-xl">Email : </span>covaicabsin@gmail.com</div>
</div>

              </div>
        </div>

        {/* <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Safety:</h3>
          <p>
            Your safety is our top priority. All our drivers undergo rigorous background checks and extensive training to ensure they meet the highest safety standards. Additionally, our vehicles are regularly inspected and sanitized to provide you with a clean and secure travel experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Customer Satisfaction:</h3>
          <p>
            We value your feedback and strive to exceed your expectations with every ride. Our dedicated customer support team is available 24/7 to assist you with any inquiries or special requests, ensuring your journey with Covai Cabs is nothing short of exceptional.                        </p>
        </div> */}



      </div>
      {/* <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Drop Taxi Services:</h3>
          <p>
            Whether you need a ride across town or to a neighboring city, our drop taxi services offer a convenient and cost-effective solution for your travel needs.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Airport Transfers:</h3>
          <p>
            Start or end your journey hassle-free with our reliable airport transfer services. We'll ensure you reach your destination on time, relaxed, and ready for your flight.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Outstation Trips:</h3>
          <p>
            Explore the beauty of Tamil Nadu and beyond with our customizable outstation trip packages. Sit back, relax, and let us take you on an unforgettable journey.
            Book Your Ride Today!
          </p>
        </div>
        <p className="mt-4">
          Experience the ultimate in convenience, reliability, and affordability with Covai Cabs. Book your ride today and discover why we're the preferred choice for drop taxi services in Tamil Nadu. Get in touch with us now to plan your next journey!
        </p>
      </div> */}
      <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Variety of Prime Brands
            </p>
            <p>Seden, Suv & Innova</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
