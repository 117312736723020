import React from 'react'
import about from "../assets/aboutgif.gif";
import border from "../assets/images/border.png"
import aboutcar from "../assets/images/aboutcar.jpg"
import taxiservice from "../assets/images/24X7taxiservice.jpg"
import airport from "../assets/images/airport transfer-covaicallcabs.jpg"
import outstation from "../assets/images/outstation trips-covaicallcabs.jpg"
import localrental from "../assets/images/local rentals-covaicallcabs.jpg"
import { FaPhone } from "react-icons/fa6";


const About = () => {
    return (
<div  id='about'>
    <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16" id="tarrif">
    <div className="text-3xl md:text-4xl font-bold mt-4 text-center">
   Welcome to Covai Cabs      </div>
    <div className="flex justify-center my-4">
    <img src={border} />
    </div>
       <div className='grid grid-cols-1  md:grid-cols-3 lg:grid-cols-4  gap-4   mt-2 pt-5 '>
         <div className="flex flex-col items-center  gap-5 rounded-xl bg-neutral-700 text-white p-4"> 
            <div className='flex justify-center'><img src={taxiservice} className='w-[70%]'/> </div>
            <h2 className="font-bold text-2xl  ">24 / 7  Availability </h2>
            <p className="text-white text-center">Need a ride at any hour? Covaicabs operates around the clock, offering reliable taxi services whenever you need them.</p>
            </div>
            <div className="flex flex-col items-center  gap-5 rounded-xl bg-neutral-700 text-white p-4"> 

            <div className='flex justify-center'> 
            <img src={localrental} className='w-[70%]'/> </div>
            <h2 className="font-bold text-2xl  ">Local City Rides </h2>
            <p className="text-white text-center">
            Navigating Coimbatore has never been easier. Our local taxi services ensure you get to your destination quickly and comfortably, whether it's a business meeting, shopping trip, or a visit to your favorite restaurant
</p>
            </div>
                     <div className="flex flex-col items-center  gap-5 rounded-xl bg-neutral-700 text-white p-4"> 

    <div className='flex justify-center'>        <img src={airport} className='w-[70%]'/> </div>
                <h2 className="font-bold text-2xl  ">Airport Transfers </h2>
            <p className="text-white text-center">
            We offer reliable airport transfer services to and from Coimbatore International Airport. With Covaicabs, you can start or end your journey with ease, knowing a dependable ride is waiting for you.                </p>

            </div>
            <div className="flex flex-col items-center  gap-5 rounded-xl bg-neutral-700 text-white p-4"> 
            <div className='flex justify-center'>  
            <img src={outstation} className='w-[70%]'/>
            </div>
            <h2 className="font-bold text-2xl  ">Outstation Trips </h2>
            <p className="text-white text-center">
            Planning a trip outside Coimbatore? Our outstation taxi services are perfect for day trips, weekend getaways, or long-distance travel. Enjoy a hassle-free journey in our comfortable, well-equipped cars.
            </p>
            </div>

        {/* <div className='flex flex-col  justify-between'>
            <div>We are Covai Cabs in Coimbatore. So, We are pleased to introduce ourselves as Travel Company. And, you will cherish to deal with any of your travel needs.We have been in the Tourism field for Several Years and Our commitment towards Quality customer service makes a difference.We have excellent executives, well trained drivers, and efficient guides to make you feel at home and to savor your stay in India.
           <img src={aboutcar} className='pt-5' /> 
          <div className='text-center'><a  href="#book_taxi_form" className='th-btn mt-3'>BOOK NOW</a></div> 
 
            </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 place-items-center gap-2'>
            <img src={taxiservice} className='w-[70%]'/>
            <img src={airport} className='w-[70%]'/>
            <img src={outstation} className='w-[70%]'/>
            <img src={localrental} className='w-[70%]'/>
        </div> */}
        
    </div>
    <div className='flex  justify-center'>
    <img src={aboutcar} className='pt-5' />  </div>

    </div>
    <div className="flex flex-col items-center md:flex-row md:justify-center gap-x-2 mt-5">
                    <div className=''>
                           <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                        </div>
                        <div className=" text-white font-bold text-lg">
              <a
                href="tel:+918883417776"
                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-3 py-2"
              >
                <span className=" flex gap-x-2 items-center justify-center">
                  <FaPhone size={17} /> CALL NOW
                </span>
              </a>
            </div>

    </div>

</div>
        // <div id='about'>
        //     <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
        //         <div className='block md:flex py-14 gap-x-20 items-center'>
        //             <div className='w-full md:w-1/2'>
        //                 <div className=' relative w-[100%]'>
        //                     <img src={about} alt="about" className='mx-auto  car_outline' />
        //                     {/* <div className=' absolute bottom-2 sm: bottom-[-70px] right-[100px] md:bottom-[10px] right-1 translate-x-1/2 md:translate-x-0 md:right-[60px]  bg-white py-4 px-4 rounded-xl shadow-md'>
        //                         <div className=' flex flex-col gap-y-1 items-center'>
        //                             <span className='primary-color text-4xl font-bold'>6+</span>
        //                             <span className=' text-lg opacity-60 font-medium'>Years Experience</span>
        //                         </div>

        //                     </div> */}
        //                 </div>
        //             </div>
        //             <div className='mt-20 md:mt-0 px-3 w-full md:w-1/2'>
        //                 <h4 className=' text-lg font-medium primary-color'>About Us</h4>
        //                 <  div className='text-3xl md:text-4xl font-bold mt-4'>
        //                     Covai Cabsaxi                        </div>
        //                 <div className="mt-4">
        //                     <h3 className='text-1xl mt-4 font-medium'> ONE WAY TAXI</h3>
        //                     <p className='mt-1 pl-4'>No Return fare! Why need to pay more for one way Taxi, We're the most trusted one.

        //                     </p>
        //                 </div>
        //                 <div className="mt-4">
        //                     <h3 className='text-1xl mt-4 font-medium'> ROUND TRIP
        //                     </h3>
        //                     <p className='mt-1 pl-4'>Your pick-up address can be anywhere in pick-up city and drop address can be.
        //                     </p>
        //                 </div>
        //                 <div className="mt-4">
        //                     <h3 className='text-1xl mt-4 font-medium'> AIRPORT PICKUP
        //                     </h3>
        //                     <p className='mt-1 pl-4'>Be it welcoming your friend at the airport right on time or any emergency situation.
        //                     </p>
        //                 </div>
        //                 <div className="mt-4">
        //                     <h3 className='text-1xl mt-4 font-medium'> OUTSTATION CAB
        //                     </h3>
        //                     <p className='mt-1 pl-4'>Our Outstation taxi are the best for short and long trips to spend quality time with.
        //                     </p>
        //                 </div>
        //                 <div className="mt-4">
        //                     <h3 className='text-1xl mt-4 font-medium'> DROP TAXI
        //                     </h3>
        //                     <p className='mt-1 pl-4'>Book a Oneway drop taxi to take you to your destination in the town, anywhere, anytime.
        //                     </p>
        //                 </div>
        //                 <div className="mt-4">
        //                     <h3 className='text-1xl mt-4 font-medium'> 100% SAFETY
        //                     </h3>
        //                     <p className='mt-1 pl-4'>Verified drivers, an emergency alert button, and live ride tracking
        //                     </p>
        //                 </div>
        //                 {/* <p className='text-black opacity-60 md:text-lg mt-5'>We're in this business Since 2018 and we provide the best services
        //        </p> */}
        //                 {/* <ul className='about-ul'>
        //                     <li className='about-li'>Easy Taxi booking in minutes</li>
        //                     <li className='about-li'>The best and transparent Pricing in the Market</li>
        //                     <li className='about-li'>Experienced skilled Drivers.

        //                     </li>

        //                 </ul> */}
        //                 <div className=' mt-5'>
        //                     <a href="#book_taxi_form" className='th-btn'>Book Now</a>
        //                 </div>
        //             </div>

        //         </div>
        //     </div>
        // </div>
    );
}

export default About