import React from 'react'
import star from '../../assets/images/star.png'
import rates from '../../assets/images/rupees.png'
import  booking from '../../assets/images/booking.png'

const PackageService = () => {
  return (
    <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16">
              <h4 className=" text-3xl font-bold primary-color text-center">
Packages      </h4>

      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3 mt-5'>
           <div className='flex flex-col items-center  gap-y-3'>
             <img src={star}/>
             <div className='text-lg font-medium'>Flat Rate Systems</div>
             <p className='text-center'>Covai Cabs  offers the best flat rates to its valuable customers, from virtually every zone</p>
           </div>
           <div className='flex flex-col items-center justify-between gap-y-3'>
             <img src={rates}/>
             <div className='text-lg font-medium'>Lower Rates
</div>
             <p className='text-center'>
             So far Covai Cabs has been always a cost-effective mode of transportation for its customers and will continue to do so.

</p>
           </div>
           <div className='flex flex-col items-center justify-between gap-y-3'>
             <img src={booking}/>
             <div className='text-lg font-medium'>Easy Online Booking
             </div>
             <p className='text-center'>
             The all new cab  onlinebooking  from Covai Cabs will make your travel customizations easier than ever before.

</p>
           </div>

      </div>
    </div>
  )
}

export default PackageService
